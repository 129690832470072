import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card } from "@mui/material";

import deliveryIcon from "./delivery-icon.svg";
import pickupIcon from "./pickup-icon.svg";
import cateringIcon from "./catering-icon.svg";
import dineInIcon from "./dinein-icon.svg";

import "./style.css";
import { setSelectedService } from "../../store/reducers/resturantsSlice";
import { useTranslation } from "react-i18next";
import {
  heroSelectScript,
  selectServiceAvailable,
} from "../../utils/conversionScripts";

const icons = {
  delivery_status: {
    id: 2,
    orderType: "delivery",
    image: deliveryIcon,
  },
  pickup_status: {
    id: 3,
    orderType: "pickUp",
    image: pickupIcon,
  },
  catering_status: {
    id: 1,
    orderType: "catering",
    image: cateringIcon,
  },
  dinein_status: {
    id: 0,
    orderType: "dineIn",
    image: dineInIcon,
  },
};

const BasicCard = (props) => {
  const {
    clickedButtonIndex,
    orderTypeFilter,
    restaurantsData,
    goToRestaurantMenuPage,
    selectedText,
    typesState,
    selectedFilter,
    filterSelectedNow,
  } = props;

  const newRestaurantData = [...restaurantsData];
  const { t } = useTranslation(["landingPage"]);

  const dispatch = useDispatch();

  const supportRestaurant = useSelector(
    (state) => state.resturants.supportRestaurant
  );

  const checkServiceStatus = (restaurant) => {
    const findRestaurant = supportRestaurant.find(
      (suppRestaurant) => suppRestaurant.id === restaurant.id
    );

    const serviceStatusObject = {
      dinein: findRestaurant?.dinein_status,
      catering: findRestaurant?.catering_status,
      delivery: findRestaurant?.delivery_status,
      pickup: findRestaurant?.pickup_status,
    };

    if (findRestaurant) {
      if (serviceStatusObject[selectedFilter]) {
        return true;
      }

      return false;
    }

    return false;
  };

  const getClassNameAccordingToFilter = (orderTypeId, restaurant) => {
    if (
      orderTypeId === clickedButtonIndex &&
      orderTypeFilter &&
      orderTypeId !== 1
    ) {
      const { delivery, near, selectedNow } = orderTypeFilter;

      if (delivery && !near) {
        return "button-icons-styles-delivery";
      }

      if (!delivery && near && checkServiceStatus(restaurant)) {
        return "button-icons-styles-near";
      }

      if (delivery && near && checkServiceStatus(restaurant)) {
        return selectedNow === "delivery"
          ? "button-icons-styles-delivery"
          : "button-icons-styles-near";
      }
    }
  };
  const getServicesIcon = (serviceName, restaurant) => {
    return (
      <div className="icon-row">
        <img
          className={getClassNameAccordingToFilter(
            icons[serviceName].id,
            restaurant
          )}
          src={icons[serviceName]?.image}
          style={{ width: iconWidth, height: iconHeight }}
        />
      </div>
    );
  };

  // Define the width and height for the SVG icons
  const iconWidth = "9px";
  const iconHeight = "11px";

  const serviceSelect = (serviceStatus, text) => {
    let noRoute;
    if (text === t("dinein") && serviceStatus?.dinein_status) {
      noRoute = "dinein";
    } else if (serviceStatus?.catering_status && text === t("catering")) {
      noRoute = "catering";
    } else if (serviceStatus?.delivery_status && text === t("delivery")) {
      noRoute = "delivery";
    } else if (serviceStatus?.pickup_status && text === t("pickup")) {
      noRoute = "pickup";
    } else {
      noRoute = null;
    }

    return noRoute;
  };

  const selectedFilterClass = (restaurant) => {
    const { delivery: availableForDelivery, near } = orderTypeFilter;

    const serviceStatusObject = {
      dinein: restaurant.dinein_status,
      catering: restaurant.catering_status,
      delivery: restaurant.delivery_status,
      pickup: restaurant.pickup_status,
    };

    // Return early if the selected filter status is not available
    if (!serviceStatusObject[selectedFilter]) return;

    const isFilterValid =
      filterSelectedNow !== t("nearMe") &&
      filterSelectedNow !== t("deliversToMe") &&
      filterSelectedNow !== "";

    if (availableForDelivery) {
      if (isFilterValid) {
        selectServiceAvailable(
          selectedText,
          selectedText === t("catering") ? null : filterSelectedNow,
          restaurant?.name_en
        );
      }
  
      return "restaurantCard-border-delivery ";
    }

    if (near && checkServiceStatus(restaurant) && filterSelectedNow !== "") {
      selectServiceAvailable(
        selectedText,
        filterSelectedNow,
        restaurant?.name_en
      );
      return "restaurantCard-border-near ";
    }

    return;
  };

  return (
    <div>
      <div
        className="restaurantCard-container container-fluid"
        id="parent-of-all"
      >
        {newRestaurantData
          .sort(
            (restaurantA, restaurantB) =>
              restaurantA.display_order - restaurantB.display_order
          )
          .map((restaurant, index) => {
            return (
              <div
                key={index}
                data-type={restaurant.id}
                className={`restaurantCards restaurantCard-large
                ${selectedFilterClass(restaurant)}`}
                id="when-deliver"
              >
                <Card
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    boxShadow: "none",
                    border: "none",
                    maxHeight: "98%",
                    position: "relative",
                  }}
                  className="restaurantCards"
                  onClick={() => {
                    heroSelectScript("Resturants", restaurant?.name_en);

                    if (selectedText !== "") {
                      dispatch(
                        setSelectedService(
                          serviceSelect(restaurant, selectedText)
                        )
                      );
                    }
                    goToRestaurantMenuPage(restaurant.id);
                  }}
                >
                  <img
                    className="restaurantCard-img"
                    src={restaurant?.image}
                    alt={`${restaurant?.name_en}`}
                  />
                  <div
                    className={`svg-container ${
                      clickedButtonIndex !== null
                        ? "expanded expanded-width"
                        : "collapsed"
                    }`}
                  >
                    <div
                      className="inner-svg-container"
                      id="inner-svg-container"
                      data-type={restaurant.id}
                    >
                      {restaurant.catering_status && (
                        <div className="icon-row">
                          <img
                            className={
                              selectedText === t("catering") &&
                              selectedText !== t("dinein") &&
                              selectedText !== t("delivery") &&
                              selectedText !== t("pickup")
                                ? "catering-bg"
                                : ""
                            }
                            src={icons["catering_status"]?.image}
                            // alt={`Icon ${i + 1}`}
                            style={{ width: "12px", height: "11px" }}
                          />
                        </div>
                      )}
                      {restaurant.delivery_status &&
                        getServicesIcon("delivery_status", restaurant)}
                      {restaurant.dinein_status &&
                        getServicesIcon("dinein_status", restaurant)}
                      {restaurant.pickup_status &&
                        getServicesIcon("pickup_status", restaurant)}
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BasicCard;
