import React, { useRef } from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import getAllRestaurantsBanner from "../../../api/home/getAllRestaurantsBanner";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { getRestaurantsBanner } from "../../../store/reducers/resturantsSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Skeleton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { retrunLanguage } from "../../../utils/languageSelect";
import { heroSelectScript } from "../../../utils/conversionScripts";

const HomeBannerSlider = () => {
  const { i18n } = useTranslation();

  // const country = useSelector((state) => state.auth.country);
  const restaurantBanner = useSelector(
    (state) => state.resturants.resturantsBanner
  );

  function sortBannersById(banners) {
    const sortedBanners = [...banners];

    return sortedBanners.sort((a, b) => a.id + b.id);
  }

  const ranOnceRef = useRef(false);
  const dispatch = useDispatch();

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    autoplay: true,
    fade: true,
  };
  const handleBannerClick = (redirectUrl) => {
    window.open(redirectUrl, "_blank");
  };

  useEffect(() => {
    if (!ranOnceRef.current) {
      getAllRestaurantsBanner(3)
        .then((res) => {
          dispatch(getRestaurantsBanner(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
      ranOnceRef.current = true;
    }
  }, []);

  return (
    <div className="slick-slider-wrapper">
      <Slider
        {...settings}
        className={retrunLanguage(i18n) ? "float-left" : ""}
      >
        {sortBannersById(restaurantBanner).map((slide, index) => (
          <div className="home-banner-images" key={index}>
            <LazyLoadImage
              onClick={() => {
                heroSelectScript("Banner", slide.name_en);

                handleBannerClick(slide.redirect_url_en);
              }}
              src={retrunLanguage(i18n) ? slide.banner_ar : slide.banner_en}
              alt="restaurant banner"
              placeholder={
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              }
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeBannerSlider;
